import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Section } from '../pages'
import Img from 'gatsby-image'
import { camelCase } from 'lodash'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const CardContainer = styled.div`
  position: relative;
  .read-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 25px 10px 39px;
    top: 0;
    background: #000000;
    border-radius: 0rem 0.875rem;
    position: absolute;
    right: 0;
    transition: transform 200ms ease, opacity 300ms ease;
    opacity: 0;
    transform: translateX(100%);
  }
  &:hover {
    .read-label {
      transform: translateX(0rem);
      opacity: 1;
    }
  }
`

export const teamCardInfos = [
  {
    name: 'Leadership',
    description: `Distribute all-hands meeting recordings, highlight priorities in updates or announcements, and keep everyone aligned.`,
    url: `/use-cases/leadership/`,
    iconName: 'leadership'
  },
  {
    name: 'Engineering',
    description: `Organize all enginering videos and updates in one place, review them asynchronously and onboard new people faster.`,
    url: `/use-cases/engineering/`,
    iconName: 'engineering'
  },
  {
    name: 'Product',
    description: `Increase visibility around product changes by sharing updates and meetings recordings asynchronously.`,
    url: `/use-cases/product/`,
    iconName: 'product'
  },
  {
    name: 'Design',
    description: `Open your design process, organize reviews and demos in one place and get cross-functional feedback.`,
    url: `/use-cases/design/`,
    iconName: 'design'
  },
  {
    name: 'Sales',
    description: `Organize customer calls in one place, accelerate training of new sales people and close more deals, faster.`,
    url: `/use-cases/sales/`,
    iconName: 'sales'
  },
  {
    name: 'Marketing',
    description: `Make customer insights more discoverable, increase visibility of marketing work and keep stakeholders in the know.`,
    url: `/use-cases/marketing/`,
    iconName: 'marketing'
  },
  {
    name: 'People Ops',
    description: `Organize and distribute learning and development content, amplify key messages and ramp up new hires, faster.`,
    url: `/use-cases/hr/`,
    iconName: 'hr'
  },
  {
    name: 'Internal Comms',
    description: `Connect people to information, make it easy to find important company comms, measure alignment and engagement.`,
    url: `/use-cases/internal-comms/`,
    iconName: 'internal-comms'
  }
]

const TeamSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        leadershipTeamIcon: file(
          relativePath: { eq: "1.3/leadership-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        engineeringTeamIcon: file(
          relativePath: { eq: "1.3/engineering-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        productTeamIcon: file(
          relativePath: { eq: "1.3/product-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        designTeamIcon: file(
          relativePath: { eq: "1.3/design-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        salesTeamIcon: file(
          relativePath: { eq: "1.3/sales-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        marketingTeamIcon: file(
          relativePath: { eq: "1.3/marketing-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hrTeamIcon: file(
          relativePath: { eq: "1.3/hr-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        internalCommsTeamIcon: file(
          relativePath: { eq: "1.3/internal-comms-team-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 48, height: 48, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Section className='py-48 tabletLToLower:py-32 grid gap-24 place-content-center tabletLToLower:!grid-cols-1 tabletLToLower:gap-12 tabletLToLower:px-16' style={{ gridTemplateColumns: 'repeat(4, minmax(0, 288px))' }}>
      {teamCardInfos.map(({ name, description, url, iconName }) => (
        <CardContainer>
          <motion.div style={{
            height: '238px',
            width: '288px',
            borderRadius: '16px',
            background: '#F7F7F7',
            padding: '24px',
            cursor: 'pointer'
          }} key={name} className='tabletLToLower:!w-full'
            transition={{
              duration: .2
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              navigate(url)
            }}
          >
            {data[`${camelCase(iconName)}TeamIcon`] ?
              <Img
                fadeIn={false}
                fixed={data[`${camelCase(iconName)}TeamIcon`].childImageSharp.fixed}
              /> : null
            }
            {/* <span className="read-label">Learn more -&gt;</span> */}
            <div className='text-2xl leading-30 mt-20 font-display'>
              {name}
            </div>
            <div className='mt-6 text-gray-foreground text-base leading-22'>
              {description}
            </div>
          </motion.div>
        </CardContainer>
      ))}
    </Section>
  )
}


export default TeamSection
