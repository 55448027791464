import { useEffect } from 'react'
import config from '../config'

const useClearbitReveal = (page = '/') => {
  useEffect(() => {
    fetch(
      `${config.backendUri}/services/website/ping`,
      {
        method: 'POST',
        body: JSON.stringify({
          page
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }
    )
  }, [])
}

export default useClearbitReveal
