import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Card, InnerSection, Section } from '../pages'
import { camelCase } from 'lodash'
import Img from 'gatsby-image'
import { isMobile } from 'react-device-detect'
import Button from './button'
import { Portal } from 'react-portal'
import styled from 'styled-components'
import { PlayVideoIcon } from '../icons'
import Modal from './modal'

const HeroVideo = () => {
  const [isHovered, setIsHovered] = React.useState(false)
  const [isFullScreen, setIsFullScreen] = React.useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const togglePlay = () => {
    setIsFullScreen(!isFullScreen)
  }

  const handleCloseModal = () => {
    setIsFullScreen(false)
  }

  const data = useStaticQuery(
    graphql`
      query {
        heroVideoThumbnail:  file(
          relativePath: { eq: "1.3/hero-video-thumbnail.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Portal>
        <Modal visible={isFullScreen} closeModal={handleCloseModal}>
          <div>
            <iframe
              src="https://player.vimeo.com/video/548851464?autoplay=1"
              width={997}
              height={494}
              frameBorder={0}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              id="hero-video"
            />
          </div>
          <CloseButton onClick={handleCloseModal}>
            <svg
              width={50}
              height={50}
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37.5 12.5l-25 25M12.5 12.5l25 25"
                stroke="#fff"
                strokeOpacity={0.9}
                strokeWidth={4.456}
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </CloseButton>
        </Modal>
      </Portal>
      <Container className='relative w-[540px] grid place-content-center tabletLToLower:w-auto tabletLToLower:block'>
        <div className='tabletLToLower:h-[223px] tabletLToLower:mb-40 tabletLToLower:mt-[35px] tabletLToLower:-mx-16 h-[338px] w-[540px] tabletLToLower:w-auto'
        >
          <Img
            fluid={data.heroVideoThumbnail.childImageSharp.fluid}
            fadeIn={false}
            style={{
              width: '100%',
              cursor: 'pointer'
            }}
          />
          <PlayVideoIcon
            className='play-icon'
            style={{
              position: 'absolute',
              left: '50%',
              top: '52%',
              transform: 'translate(-50%, -50%)',
              transition: 'all 200ms'
            }}
          />
        </div>
        <div className='z-20 absolute left-0 top-0 w-full h-full cursor-pointer'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={togglePlay}
        >
        </div>
      </Container>
    </>
  )
}

export default HeroVideo

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: none;
  outline: none;
  position: fixed;
  cursor: pointer;
  right: -50px;
  top: -50px;
`

const Container = styled.div`
  &:hover {
    .play-icon {
      transform: scale(1.1) translate(-45%, -45%) !important;
    }
  }
`