import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Card, InnerSection, Section } from '../pages'
import { camelCase } from 'lodash'
import Img from 'gatsby-image'
import { isMobile } from 'react-device-detect'

const HeroVideoBackground = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        heroVideoBg: file(
          relativePath: { eq: "1.3/hero-video-bg.png" }
        ) {
          childImageSharp {
            fluid(maxHeight: 587, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroVideoBgMobile: file(
          relativePath: { eq: "1.3/hero-video-bg-mobile.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <div className={'tabletLToLower:!grid !hidden absolute left-0 top-[344px] w-full -z-10 place-content-center h-[303.38px] ' + className}>
        <Img
          fluid={data.heroVideoBgMobile.childImageSharp.fluid}
          fadeIn={false}
          style={{
            width: '100vw',
            height: '100%'
          }}
        />
      </div>
      <div className='tabletLToLower:!hidden !block relative w-full'>
        <div className={'absolute left-0 -top-[64px] w-full h-[587px] -z-10 grid place-content-center ' + className}>
          <Img
            fluid={data.heroVideoBg.childImageSharp.fluid}
            fadeIn={false}
            style={{
              width: '1440px',
              height: '587px'
            }}
          />
        </div>
      </div>
    </>
  )
}

export default HeroVideoBackground
