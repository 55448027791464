import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'

import Nav from '../components/nav'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Box from '../components/box'
import checkUtmParams from '../utils/checkUtmParams'
import useHotjarScript from '../utils/hooks/useHotjarScript'
import { CheckMarkIcon, PlayVideoIcon } from '../icons'
import IntegrationSection from '../components/integrationsSection'
import CloudAcademySection from '../components/cloudAcademySection'
import HeroVideoBackground from '../components/heroVideoBackground'
import CommonButton from '../components/button'
import TeamSection from '../components/teamSection'
import { AnimatePresence, motion } from 'framer-motion'
import { isMobileView } from '../utils/isMobileView'
import { backgroundColor } from '../../data/SiteConfig'
import BookCallModal from '../components/bookCallModal'
import HeroVideo from '../components/heroVideo'
import ProfileSectionVideo from '../assets/videos/profile-section.mp4'
import UpdatesVideo from '../assets/videos/updates.mp4'
import VideosVideo from '../assets/videos/videos.mp4'
import useClearbitReveal from '../utils/hooks/useClearbitReveal'


const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        organizeVideos: file(
          relativePath: { eq: "1.3/organize-videos.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        homeReader: file(
          relativePath: { eq: "1.3/home-reader.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        homeMobileReader: file(
          relativePath: { eq: "1.3/home-mobile-reader.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroVideoThumbnail:  file(
          relativePath: { eq: "1.3/hero-video-thumbnail.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 540, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const integrations = []
  const [rotatingTextIndex, setRotatingTextIndex] = useState(0);

  const texts = [
    'video recordings',
    'announcements',
    'team updates',
    'internal decisions',
    'important comms'
  ]

  const toExtensionDownload = () => {
    navigate('browser-extension')
  }

  useEffect(() => {
    checkUtmParams()
  }, [])

  useClearbitReveal('/')

  useEffect(() => {
    setTimeout(() => {
      let next = rotatingTextIndex + 1;
      if (next === texts.length) {
        next = 0;
      }
      setRotatingTextIndex(next);
    }, 2.2 * 1000);
  }, [rotatingTextIndex, setRotatingTextIndex]);

  useHotjarScript()

  const isMobile = isMobileView()
  const [isBookCallModalOpen, setIsBookCallModalOpen] = React.useState(false)

  return (
    <>
      <Nav />
      <Box>
        <BookCallModal
          open={isBookCallModalOpen}
          onClose={() => {
            setIsBookCallModalOpen(false)
          }}
        />
        <SEO
          title="Pulse - Message boards for modern teams"
          description="Pulse is a platform for team alignment. Engineered to help modern teams stay more connected and aligned by communicating more openly and asynchronously."
          ogImage="/assets/ogimages/pulse.png"
          twImage="/assets/ogimages/twitter/pulse.png"
        />
        <HeroVideoBackground className='tabletLToLower:top-[459px]' />
        <div
          className='mt-[64px]'
        >
          <Section>
            <InnerSection className='flex justify-between pt-48 pb-35 tabletLToLower:flex-col'>
              <div
                className='w-[496px] tabletLToLower:w-full'
              >
                <div className='font-display relative leading-[64px] text-[60px] tabletLToLower:text-4xl tabletLToLower:leading-[42px] tabletLToLower:text-center' >
                  Organize and distribute all your<br />
                  {/* <AnimatePresence> */}
                  <motion.span
                    variants={{
                      enter: direction => {
                        return {
                          y: -20,
                          opacity: 0
                        };
                      },
                      center: {
                        zIndex: 1,
                        y: 0,
                        opacity: 1
                      },
                      exit: direction => {
                        return {
                          zIndex: 0,
                          opacity: 0
                        };
                      }
                    }}
                    key={rotatingTextIndex}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      y: { type: "spring", stiffness: 300, damping: 200 },
                      opacity: { duration: 0.5 },
                    }}
                    style={{
                      position: 'absolute'
                    }}
                    className='tabletLToLower:w-full tabletLToLower:left-0'
                  >
                    {texts[rotatingTextIndex]}
                  </motion.span>
                  {/* </AnimatePresence> */}
                </div>
                <div className='h-[64px] tabletLToLower:h-[32px]' />
                <div className='mt-16 text-gray-foreground font-display tabletLToLower:text-center tabletLToLower:text-base tabletLToLower:leading-[20px] max-w-[420px] text-xl leading-24'>
                  Pulse helps remote and hybrid teams organize and distribute their most important communications. Announcements, internal memos, team updates, discussions and meetings recordings – instantly transcribed, searchable, and available on-demand all, to everyone in your team.
                </div>
                <div className='mt-24 flex tabletLToLower:justify-center'>
                  <CommonButton to='/signup/' className='px-18'>
                    <span className='tabletLToLower:hidden block'>
                      Try Pulse for free
                    </span>
                    <span className='tabletLToLower:block hidden'>
                      Create an account
                    </span>
                  </CommonButton>
                  <CommonButton className='ml-12 px-18 tabletLToLower:!hidden' secondary onClick={() => setIsBookCallModalOpen(true)}>
                    Get Demo
                  </CommonButton>
                </div>
              </div>
              <HeroVideo />
            </InnerSection>
          </Section>
          <Section
            className='flex justify-center text-center pt-96 tabletLToLower:pt-64 pb-12'
          >
            <InnerSection style={{ width: '650px' }} className='tabletLToLower:!w-full'>
              <div className='text-5xl leading-52 font-display tabletLToLower:text-4xl tabletLToLower:leading-[42px] text-center'>
                Teams updates and videos in one central place
              </div>
              <div className='text-gray-foreground leading-24 text-xl mt-16 flex justify-center tabletLToLower:text-base tabletLToLower:leading-[20px]'>
                <div style={{ width: '496px' }} className='tabletLToLower:!w-full'>
                  Transcribed, searchable, and easy to find so that everyone is always in sync and aligned.
                </div>
              </div>
            </InnerSection>
          </Section>
          <TeamSection />
          <Section className='py-48 tabletLToLower:py-32'>
            <InnerSection className='grid gap-24 sm:gap-16 grid-cols-2 tabletLToLower:grid-cols-1'>
              <div
                className='sm:w-full sm:h-[238px] p-24 sm:p-14 sm:overflow-hidden'
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 37.78%, rgba(240, 240, 240, 0.6) 100%), linear-gradient(90deg, #ECE2D2 8.25%, #DBD3E7 91.17%)`,
                  borderRadius: `16px`
                }}
              >
                <video autoPlay loop muted playsInline style={{
                  borderRadius: '3px'
                }}>
                  <source src={VideosVideo} type="video/mp4" />
                </video>
              </div>
              <Card style={{
                paddingTop: '55px',
                backgroundColor: isMobile ? '#FAFAFA' : '',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}>
                <div className='text-3xl leading-36 font-display tabletLToLower:text-2xl tabletLToLower:leading-[30px] tabletLToLower:text-left'>
                  Distribute video recordings.
                  <Box className={'text-gray-foreground'}>
                    All searchable and transcribed
                  </Box>
                </div>
                <div className='mt-16 text-gray-foreground text-lg leading-24 tabletLToLower:text-base tabletLToLower:leading-22 max-w-[460px]'>
                  From all-hands and team updates to onboarding and customer demos, make all meetings available on-demand so that non-participants can rewatch them on their own time.
                </div>

                <div className='mt-24 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Integrated with Zoom, Google Meet, Loom and Drive
                    </div>
                  </div>
                </div>
                <div className='mt-8 text-lg leading-24  tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Slack alerts and emails notification on new videos
                    </div>
                  </div>
                </div>
                <div className='mt-8 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Meetings automatically transcribed & searchable
                    </div>
                  </div>
                </div>

              </Card>
            </InnerSection>
            <InnerSection className='grid gap-24 sm:gap-16 grid-cols-2 justify-between mt-48 tabletLToLower:mt-16 tabletLToLower:grid-cols-1'>
              <div
                className='sm:w-full sm:h-[238px] p-24 sm:p-14 sm:overflow-hidden'
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 37.78%, rgba(240, 240, 240, 0.8) 100%), linear-gradient(90deg, #D3E2D9 8.25%, #E9D7E2 91.17%)`,
                  borderRadius: `16px`
                }}
              >
                <video autoPlay loop muted playsInline style={{
                  borderRadius: '3px'
                }}>
                  <source src={UpdatesVideo} type="video/mp4" />
                </video>
              </div>
              <Card style={{
                paddingTop: '55px',
                backgroundColor: isMobile ? '#FAFAFA' : '',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}>
                <div className='text-3xl leading-36 font-display tabletLToLower:text-2xl tabletLToLower:leading-[30px] tabletLToLower:text-left'>
                  Post and discuss updates.
                  <Box className={'text-gray-foreground'}>
                    Keep people aligned with clarity.
                  </Box>
                </div>
                <div className='mt-16 text-gray-foreground text-lg leading-24 tabletLToLower:text-base tabletLToLower:leading-22 max-w-[460px]'>
                  Cut through the noise. Keep quick conversations in Slack or Discord, and announcements, updates, and decisions in Pulse so that everyone can asynchronously catch up.
                </div>

                <div className='mt-24 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Slack alerts and emails notifications on new posts
                    </div>
                  </div>
                </div>
                <div className='mt-8 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Analytics to understand who saw what
                    </div>
                  </div>
                </div>
                <div className='mt-8 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Mark comments as decisions
                    </div>
                  </div>
                </div>

              </Card>
            </InnerSection>
            <InnerSection className='grid gap-24 sm:gap-16 grid-cols-2 justify-between mt-48 tabletLToLower:mt-16 tabletLToLower:grid-cols-1'>
              <div
                className='sm:w-full sm:h-[238px] p-24 sm:p-14 sm:overflow-hidden'
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 37.78%, rgba(240, 240, 240, 0.8) 100%), linear-gradient(90deg, #E9E4F3 4.17%, #D3EBEC 94.14%)`,
                  borderRadius: `16px`,
                }}
              >
                <video autoPlay loop muted playsInline style={{
                  borderRadius: '3px'
                }}>
                  <source src={ProfileSectionVideo} type="video/mp4" />
                </video>
              </div>
              <Card style={{
                paddingTop: '55px',
                backgroundColor: isMobile ? '#FAFAFA' : '',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}>
                <div className='text-3xl leading-36 font-display tabletLToLower:text-2xl tabletLToLower:leading-[30px] tabletLToLower:text-left'>
                  Public profiles.
                  <Box className={'text-gray-foreground'}>
                    Help people get to know each other.
                  </Box>
                </div>
                <div className='mt-16 text-gray-foreground text-lg leading-24 tabletLToLower:text-base tabletLToLower:leading-22 max-w-[460px]'>
                  Great to see at glance where you team members are based, what they wrote in the last posts or said in the last meetings. With social links, time zones, birthdays, anniversaries and more.
                </div>

                <div className='mt-24 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      All posts they wrote and meetings they attended
                    </div>
                  </div>
                </div>
                <div className='mt-8 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Personal decisions-log, social links and more
                    </div>
                  </div>
                </div>
                <div className='mt-8 text-lg leading-24 tabletLToLower:text-lg'>
                  <div className='flex text-center align-center tabletLToLower:text-left'>
                    <CheckMarkIcon />
                    <div className='ml-8 font-display'>
                      Import people from Slack, Okta, etc...
                    </div>
                  </div>
                </div>

              </Card>
            </InnerSection>
          </Section>

          <Section>
            <InnerSection className='pt-48 px-104 bg-gray-foreground-100 tabletLToLower:hidden' style={{ borderRadius: '32px' }}>
              <div className='flex justify-center'>
                <div className='text-5xl leading-52 w-640 text-center font-display'>
                  Organize content around anything that matters.
                </div>
              </div>
              <div className='flex justify-center'>
                <div className='mt-16 text-gray-foreground text-xl leading-24 text-center w-496'>
                  From all-hands meetings and announcements, to team updates and employee onboarding material. Easy to browse, search and consume for anyone.
                </div>
              </div>
              <div className='mt-48 tabletLToLower:mt-16'>
                <Img
                  fluid={data.organizeVideos.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
            </InnerSection>
          </Section>
          <Section>
            <InnerSection className='mt-24 grid grid-cols-2 gap-24 font-display tabletLToLower:hidden'>
              <div className='bg-gray-foreground-100 text-3xl leading-36 pt-48 h-[630px]' style={{ borderRadius: '32px' }}>
                <div className='w-full max-w-[392px] mx-48 '>
                  <span>
                    Facilitate discussions.
                  </span>
                  <span className='text-gray-foreground'>
                    {' '}Ask questions and follow up with more in-depth discussions.
                  </span>
                </div>
                <div className='mt-52 flex justify-center'>
                  <Img
                    fluid={data.homeReader.childImageSharp.fluid}
                    fadeIn={false}
                    style={{
                      width: '543px'
                    }}
                  />
                </div>
              </div>
              <div className='bg-gray-foreground-100 text-3xl leading-36 pt-48 h-[630px]' style={{ borderRadius: '32px' }}>
                <div className='mx-48 w-full max-w-[392px]'>
                  <span>
                    Make scalable meetings.
                  </span>
                  <span className='text-gray-foreground'>
                    {' '}Videos that everyone can rewatch anytime, anywhere.
                  </span>
                </div>
                <div className='mt-52 px-[134px]'>
                  <Img
                    fluid={data.homeMobileReader.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </div>
              </div>
            </InnerSection>
          </Section>
          <div className='mt-144 tabletLToLower:mt-64' />
          <IntegrationSection />
          <CloudAcademySection />
        </div>
        <Footer />
      </Box>
    </>
  )

}

export const Button = CommonButton

export const Section = styled.div`
  margin-left: 108px;
  margin-right: 108px;
  display: grid;
  place-content: center;
  @media ${props => props.theme.device.tabletLToLower} {
    margin-left: 0px;
    margin-right: 0px;
  }
`

export const InnerSection = styled.div`
  width: 1224px;
  @media ${props => props.theme.device.tabletLToLower} {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const Card = styled.div`
  border-radius: 16px;
  background: #F7F7F7;
  padding: 24px;
`

// old components

export const H1 = styled.h1`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-style: normal;
  font-weight: 900;
  font-size: 3rem;
  line-height: 3.625rem;
  margin: 0px;
  margin-bottom: 2.5rem;
  color: ${props => props.theme.core.content.inversePrimary};
`

export const H3 = styled.h3`
  font-size: 2rem;
  line-height: 2.625rem;
  color: #ffffff;
  margin: 0px;
  margin-bottom: 2.5rem;
  font-family: ${props => props.theme.typography.fontFamilyGTAmericaBold};
`

export const CopyBodyH2 = styled.h2`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #ffffff;
  max-width: 100%;
  z-index: 2;
  position: relative;
`

export const CopyBody = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #ffffff;
  max-width: 100%;
  z-index: 2;
  position: relative;
`

export const CopySubText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
`

export const Li = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  svg {
    margin-right: 1rem;
  }
`

export const Blue = styled.span`
  color: #93d3f4;
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  align-items: center;
  svg {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

export const Yellow = styled.span`
  color: #fae6a3;
  display: inline;
  ${props =>
    props.centered &&
    css`
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      align-items: center;
    `}
  svg {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

export const Purple = styled.span`
  color: #d9a3ff;
  display: inline;
  ${props =>
    props.centered &&
    css`
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      align-items: center;
    `}
  svg {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

export const Green = styled.span`
  color: #7bd7b3;
  display: inline;
  ${props =>
    props.centered &&
    css`
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      align-items: center;
    `}
  svg {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

export const Pink = styled.span`
  color: #ec8483;
  display: inline;
  ${props =>
    props.centered &&
    css`
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      align-items: center;
    `}
  svg {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

export const Uppercase = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #afafaf;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
`

export const TryPulse = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  #pricing-levels {
    display: flex;
    padding-left: 70px;
  }
`

export default IndexPage
