import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Card, InnerSection, Section } from '../pages'
import { camelCase } from 'lodash'
import Img from 'gatsby-image'
import { isMobile } from 'react-device-detect'
import Button from './button'

const CloudAcademySection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        cloudAcademyTeam: file(
          relativePath: { eq: "1.3/cloud-academy-team.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 530, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cloudAcademyTeamMobile: file(
          relativePath: { eq: "1.3/cloud-academy-team.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cloudAcademyIcon: file(
          relativePath: { eq: "1.3/cloud-academy-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 32, height: 32, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Section className='tabletLToLower:!block !hidden'>
        <InnerSection className='px-16 h-auto mb-32'>
          <div style={{ borderRadius: '16px', background: '#F7F7F7' }} className='flex overflow-hidden flex-col'>
            <div className='relative h-[256px] w-full font-display overflow-hidden'>
              <Img
                fluid={data.cloudAcademyTeamMobile.childImageSharp.fluid}
                fadeIn={false}
                style={{
                  width: '100%'
                }}
              />
              <div className='absolute top-[47px] left-24'>
                <div className='text-5xl leading-52 text-white'>
                  200+
                </div>
                <div className='text-white text-sm leading-17 opacity-50'>
                  Full-time employees
                </div>
                <div className='text-5xl leading-52 text-white mt-24'>
                  10+
                </div>
                <div className='text-white text-sm leading-17 opacity-50'>
                  {`Employees' time zones`}
                </div>
              </div>
            </div>
            <div className='p-24'>
              <div className='flex font-display'>
                <div className='text-gray-foreground text-lg leading-20'>
                  From
                </div>
                <div className='ml-5 mr-6'>
                  <Img
                    fadeIn={false}
                    fixed={data.cloudAcademyIcon.childImageSharp.fixed}
                  />
                </div>
                <div className='underline text-gray-foreground-900 text-lg leading-20'>
                  Cloud Academy
                </div>
              </div>
              <div className='mt-16 text-base leading-20 text-gray-foreground-900 font-display'>
                {`“Our people in the US, New Zealand or Switzerland want to know what's happening at Cloud Academy and why. With Pulse we answer that question every morning, for every team, role and project.”`}
              </div>
              <div className='text-gray-foreground text-base leading-20 mt-16 font-display'>
                Stefano Bellasio, CEO
              </div>
              <div className='mt-24'>
                <Button to='/blog/cloud-academy/' className='w-full'>
                  Read the case study
                </Button>
              </div>
            </div>
          </div>
        </InnerSection>
      </Section>
      <Section className='tabletLToLower:!hidden'>
        <InnerSection className='my-96 flex'>
          <div style={{ borderRadius: '32px', background: '#F7F7F7' }} className='flex overflow-hidden'>
            <div style={{ width: '695.49px' }} className='p-48'>
              <div className='flex font-display'>
                <div className='text-gray-foreground text-2xl leading-32'>
                  We partnered with
                </div>
                <div className='ml-8 mr-6'>
                  <Img
                    fadeIn={false}
                    fixed={data.cloudAcademyIcon.childImageSharp.fixed}
                  />
                </div>
                <div className='underline text-gray-foreground-900 text-2xl leading-32'>
                  Cloud Academy
                </div>
              </div>
              <div className='mt-16 text-3xl leading-36 font-display'>
                {`“Our people in the US, New Zealand or Switzerland want to know what's happening at Cloud Academy and why. With Pulse we answer that question every morning, for every team, role and project.”`}
              </div>
              <div className='text-gray-foreground text-2xl leading-32 mt-16 font-display'>
                Stefano Bellasio, CEO
              </div>
              <div className='mt-24'>
                <Button to='/blog/cloud-academy/'>
                  Read the case study
                </Button>
              </div>
            </div>
            <div className='relative h-[472px] w-[528.51px] font-display'>
              <Img
                fluid={data.cloudAcademyTeam.childImageSharp.fluid}
                fadeIn={false}
              />
              <div className='absolute top-48 left-48'>
                <div className='text-5xl leading-52 text-white'>
                  200+
                </div>
                <div className='text-white text-sm leading-17 opacity-50'>
                  Full-time employees
                </div>
                <div className='text-5xl leading-52 text-white mt-24'>
                  10+
                </div>
                <div className='text-white text-sm leading-17 opacity-50'>
                  {`Employees' time zones`}
                </div>
              </div>
            </div>
          </div>
        </InnerSection>
      </Section>
    </>
  )
}

export default CloudAcademySection
