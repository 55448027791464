import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Card, InnerSection, Section } from '../pages'
import { camelCase } from 'lodash'
import Img from 'gatsby-image'

const IntegrationSection = ({ team = 'leadership' }) => {
  const data = useStaticQuery(
    graphql`
      query {
        zoomIcon: file(
          relativePath: { eq: "1.3/zoom-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        driveIcon: file(
          relativePath: { eq: "1.3/drive-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        slackIcon: file(
          relativePath: { eq: "1.3/slack-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        discordIcon: file(
          relativePath: { eq: "1.3/discord-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        meetIcon: file(
          relativePath: { eq: "1.3/meet-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        oktaIcon: file(
          relativePath: { eq: "1.3/okta-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        loomIcon: file(
          relativePath: { eq: "1.3/loom-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        dropboxIcon: file(
          relativePath: { eq: "1.3/dropbox-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        web3AuthIcon: file(
          relativePath: { eq: "1.3/web-3-icon.png" }
        ) {
          childImageSharp {
            fixed(width: 36, height: 36, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  let integrations = [
    {
      name: 'Zoom',
      description: `Import any recorded meeting or sync to automatically distribute new ones.`
    },
    {
      name: 'Drive',
      description: `Import any video that lives in your Google Drive account.`
    },
    {
      name: 'Slack',
      description: `Notify one or more channels when new posts or videos are shared.`
    },
    {
      name: 'Discord',
      description: `Notify one or more channels when new posts or videos are shared.`
    },
    {
      name: 'Meet',
      description: `Import any recorded meeting or sync to automatically distribute new ones.`
    },
    {
      name: 'Okta',
      description: `Manage access by allowing your team to login via SAML SSO.`
    },
    {
      name: 'Loom',
      description: `Import by URL any video that lives in your Loom account.`
    },
    {
      name: 'Dropbox',
      description: `Import any video that lives in your Team or Personal Dropbox account.`
    },
    {
      name: 'Web3 Auth',
      description: `Manage access by allowing login via Ethereum wallet. (Coming soon)`
    }
  ]

  if (team === 'leadership') {
    integrations = integrations.filter(app => [
      'Zoom',
      'Drive',
      'Slack',
      'Discord',
      'Meet',
      'Okta',
      // 'Loom',
      // 'Dropbox',
    ].includes(app.name))
  }

  if (team === 'engineering') {
    integrations = integrations.filter(app => [
      'Zoom',
      'Drive',
      'Slack',
      // 'Discord',
      'Meet',
      // 'Okta',
      'Loom',
      'Dropbox',
    ].includes(app.name))
  }

  if (team === 'product' || team === 'design') {
    integrations = integrations.filter(app => [
      'Zoom',
      'Drive',
      'Slack',
      'Discord',
      'Meet',
      // 'Okta',
      // 'Loom',
      'Dropbox',
    ].includes(app.name))
  }

  if (team === 'dao') {
    integrations = integrations.filter(app => [
      'Zoom',
      'Drive',
      // 'Slack',
      'Discord',
      'Meet',
      // 'Okta',
      // 'Loom',
      'Dropbox',
      'Web3 Auth'
    ].includes(app.name))
  }

  if (team === 'internal-comms') {
    integrations = integrations.filter(app => [
      'Zoom',
      'Drive',
      'Slack',
      'Discord',
      'Meet',
      // 'Okta',
      // 'Loom',
      'Dropbox',
      // 'Web3 Auth'
    ].includes(app.name))
  }

  return (
    <>
      <Section>
        <InnerSection className='text-center grid place-content-center mb-12 tabletLToLower:mb-0'>
          <div className='text-5xl leading-52 w-600 font-display tabletLToLower:w-full tabletLToLower:text-4xl tabletLToLower:leading-[42px]'>
            Integrated in your workflows
          </div>
          <div className='grid place-content-center'>
            <div className='mt-16 text-gray-foreground text-xl leading-24 w-554 text-center tabletLToLower:text-base tabletLToLower:leading-20 tabletLToLower:w-full'>
              We integrate into your current systems to let you import videos, sync recurring meetings, send notifications alert and much more.
            </div>
          </div>
        </InnerSection>
      </Section>
      <Section>
        <InnerSection className='grid gap-24 grid-cols-3 place-content-center my-48 tabletLToLower:mt-44 tabletLToLower:mb-64 tabletLToLower:grid-cols-1 tabletLToLower:gap-12 tabletLToLower:px-16'>
          {integrations.map(({ name, description }) => (
            <Card style={{
              height: '238px',
              width: '392px'
            }} key={name} className='flex flex-col justify-between tabletLToLower:!w-full'>
              <div className='flex'>
                {data[`${camelCase(name.split(' ').join(''))}Icon`] ?
                  <Img
                    fadeIn={false}
                    fixed={data[`${camelCase(name.split(' ').join(''))}Icon`].childImageSharp.fixed}
                  /> : null
                }
                <div className='text-3xl leading-36 ml-12 underline font-display'>
                  {name}
                </div>
              </div>
              <div className='text-xl leading-26 font-display'>
                {description}
              </div>
            </Card>
          ))}
        </InnerSection>
      </Section>
    </>
  )
}

export default IntegrationSection
